var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('el-card',{staticStyle:{"height":"98%"}},[_c('div',{staticClass:"box-top"},[_c('el-input',{staticStyle:{"width":"150px","margin-right":"10px"},attrs:{"placeholder":"试卷id","size":"mini","clearable":""},model:{value:(_vm.examinationID),callback:function ($$v) {_vm.examinationID=$$v},expression:"examinationID"}}),_c('el-select',{attrs:{"placeholder":"年级","size":"mini"},on:{"change":function (val) { return _vm.changeInquire(val, 'grade'); }},model:{value:(_vm.grade.value),callback:function ($$v) {_vm.$set(_vm.grade, "value", $$v)},expression:"grade.value"}},_vm._l((_vm.gradeData),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-select',{attrs:{"placeholder":"学科","size":"mini"},on:{"change":function (val) { return _vm.changeInquire(val, 'subject'); }},model:{value:(_vm.subject.value),callback:function ($$v) {_vm.$set(_vm.subject, "value", $$v)},expression:"subject.value"}},_vm._l((_vm.subjectData),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":_vm.seeAbout}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":_vm.newExamination}},[_vm._v("新建试卷")])],1),_c('el-main',{staticClass:"box-card"},[_c('el-table',{staticStyle:{"width":"100%","margin-top":"25px","margin-bottom":"25px"},attrs:{"border":"","data":_vm.tableData}},[_c('el-table-column',{attrs:{"label":"编号","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.id))]}}])}),_c('el-table-column',{attrs:{"label":"年级","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.gradeName))]}}])}),_c('el-table-column',{attrs:{"label":"学科","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.subjectName))]}}])}),_c('el-table-column',{attrs:{"label":"试卷名称","width":"680","align":"center","show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.createTime))]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"400","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEnter(scope.$index, scope.row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{staticClass:"pager",attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.page,"page-size":10,"total":_vm.total},on:{"current-change":_vm.changePaging}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
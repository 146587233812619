<template>
  <div class="box">
    <el-card style="height:98%">
      <div class="box-top">
        <el-input
          placeholder="试卷id"
          size="mini"
          style="width: 150px; margin-right: 10px"
          v-model="examinationID"
          clearable
        ></el-input>
        <el-select
          v-model="grade.value"
          placeholder="年级"
          size="mini"
          @change="(val) => changeInquire(val, 'grade')"
        >
          <el-option
            v-for="item in gradeData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="subject.value"
          placeholder="学科"
          size="mini"
          @change="(val) => changeInquire(val, 'subject')"
        >
          <el-option
            v-for="item in subjectData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="success" size="mini" @click="seeAbout">查询</el-button>
        <el-button type="success" size="mini" @click="newExamination">新建试卷</el-button>
      </div>
      <!-- //表格 -->

      <el-main class="box-card">
        <el-table
          border
          :data="tableData"
          style="width: 100%; margin-top: 25px; margin-bottom: 25px"
        >
          <el-table-column label="编号" width="80" align="center">
            <template slot-scope="{ row }">{{ row.id }}</template>
          </el-table-column>
          <el-table-column label="年级" width="100" align="center">
            <template slot-scope="{ row }">{{ row.gradeName }}</template>
          </el-table-column>
          <el-table-column label="学科" width="100" align="center">
            <template slot-scope="{ row }">{{ row.subjectName }}</template>
          </el-table-column>
          <el-table-column label="试卷名称" width="680" align="center" :show-overflow-tooltip="true">
            <template slot-scope="{ row }">{{ row.name }}</template>
          </el-table-column>

          <el-table-column label="创建时间" width="180" align="center">
            <template slot-scope="{ row }">{{ row.createTime }}</template>
          </el-table-column>
          <el-table-column label="操作" width="400" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEnter(scope.$index, scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="10"
          :total="total"
          @current-change="changePaging"
          class="pager"
        ></el-pagination>
      </el-main>
    </el-card>
  </div>
</template>

<script>
import store from "../../../store/PaperTest/index.js";
import { mapGetters } from "vuex";
import { examList, examDelete } from "../../../api/api";
import { transformTimestamp } from "../../../components/time";
export default {
  data () {
    return {
      //年级
      gradeData: this.parameter.grade(),
      //班级
      subjectData: this.parameter.subject(),
      examinationID: null,
      allow_go: true,
    };
  },
  mounted () {
    this.seeAbout();
  },

  methods: {
    init (params) {
      examList(params).then((res) => {
        res.data.list.map((item) => {
          var grade = this.gradeData.find((fin) => {
            return fin.value == item.gradeLevel;
          });
          item["gradeName"] = grade.label;
          var subject = this.subjectData.find((fin) => {
            return fin.value == item.subjectId;
          });
          item["subjectName"] = subject.label;
          item["createTime"] = transformTimestamp(item.createTime);
        });
        store.commit("setTableData", res.data.list);
        store.commit("setTotal", res.data.total);
      });
    },
    //点击查询
    changeInquire (val, name) {
      switch (name) {
        case "grade":
          let gradeName = this.gradeData.find((item) => {
            return item.value == val;
          });
          store.commit("setGrade", {
            value: gradeName.value,
            label: gradeName.label,
          });
          store.commit("setSubject", { value: "", label: "" });
          store.commit("setTableData", []);
          store.commit("setPage", 1);
          store.commit("setTotal", 0);
          this.examinationID = null;
          this.seeAbout();
          break;
        case "subject":
          let subjectName = this.subjectData.find((item) => {
            return item.value == val;
          });
          store.commit("setSubject", {
            value: subjectName.value,
            label: subjectName.label,
          });
          store.commit("setTableData", []);
          store.commit("setPage", 1);
          store.commit("setTotal", 0);
          this.examinationID = null;
          this.seeAbout();
          break;
        case "":
          break;
        default:
          break;
      }
    },
    //查询
    seeAbout () {
      if (this.examinationID == "" || this.examinationID == null) {
        this.init({
          pageNum: this.page,
          pageSize: 10,
          subjectId: this.subject.value,
          gradeLevel: this.grade.value,
        });
      } else {
        store.commit("setGrade", { value: null, label: null });
        store.commit("setSubject", { value: null, label: null });
        this.init({
          id: this.examinationID,
        });
      }
    },
    //新建试卷
    newExamination () {
      this.$router.push({
        name: "newExaminationPaper",
        query: { id: "add" },
      });
    },
    //进入 编辑试卷详情
    handleEnter (index, row) {
      this.$router.push({
        name: "newExaminationPaper",
        query: { id: row.id },
      });
    },
    //删除
    handleDelete (index, row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var id = row.id;
          examDelete({ id: id }).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.seeAbout(); //刷新数据
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //分页
    changePaging (val) {
      store.commit("setPage", val);
    },
    //关闭弹窗
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
  },
  computed: {
    total () {
      return store.state.total;
    },
    page () {
      return store.state.page;
    },
    tableData () {
      return store.state.tableData;
    },
    subject () {
      return store.state.subject;
    },
    grade () {
      return store.state.grade;
    },
    // examinationID() {
    //   return store.state.examinationID;
    // },
  },
};
</script>

<style lang='less' scoped>
@import '../../../style/k12SetQuestion/index.less';
@import '../../../style/mouse.less';
</style>
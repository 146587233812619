//单元小测试
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    examinationID: 788,
    grade: {
      value: 1,
      label: "一年级"
    }, //年级
    subject: {
      value: 1,
      label: "数学"
    }, //学科
    chapterData: [],
    page: 1,
    tableData: [],
    total: 0, //总页数
    Variantid: '', //变式id
    flag: false

  },
  getters: {

  },
  mutations: {
    setGrade: (state, grade) => {
      state.grade = grade
    },
    setVariantid: (state, Variantid) => {
      state.Variantid = Variantid
    },
    setTableData: (state, tableData) => {
      state.tableData = tableData
    },
    setPage: (state, page) => {
      state.page = page
    },
    setTotal: (state, total) => {
      state.total = total
    },
    setPage: (state, page) => {
      state.page = page
    },
    setTotal: (state, total) => {
      state.total = total
    },
    setSubject: (state, subject) => {
      state.subject = subject
    },
    setChapter: (state, chapter) => {
      state.chapter = chapter
    },
    setChapterData: (state, chapterData) => {
      state.chapterData = chapterData
    },

    setCollapse: (state, toggle_collapsed) => {
      state.toggle_collapsed = toggle_collapsed
    },


  },
  actions: {},
  modules: {}
})